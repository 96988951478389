// 日付出力
// ==================================================
function getDateString() {
  var d     = new Date();
  var wday  = new Array('日','月','火','水','木','金','土');
  var year  = d.getFullYear();
  var month = d.getMonth() + 1;
  var days  = d.getDate();
  var iDay  = d.getDay();
  var sDate = 'yyyy年MM月dd日（week）';

  sDate = sDate.replace('MM', month);
  sDate = sDate.replace('dd', days);
  sDate = sDate.replace('week', wday[iDay]);
  sDate = sDate.replace('yyyy', year);
  return sDate;
}
